import React, { Suspense } from 'react'
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import { CContainer, CFade } from '@coreui/react'

// routes config
import routes from '../routes'
import TheLayout from './TheLayout'
import AlertSystemPage from '../views/pages/AlertSystemPage/AlertSystemPage'
  
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// const TheContent = () => {
//   return (
//     <main className="c-main">
//       <CContainer fluid>
//         <Suspense fallback={loading}>
//           <Switch>
//             {routes.map((route, idx) => {
//               return route.component && (
//                 <Route
//                   key={idx}
//                   path={route.path}
//                   exact={route.exact}
//                   name={route.name}
//                   render={props => (
//                     <CFade>
//                       <route.component {...props} />
//                     </CFade>
//                   )} />
//               )
//             })}
//             <Redirect from="/" to="/dashboard" />
//           </Switch>
//         </Suspense>
//       </CContainer>
//     </main>
//   )
// }

// export default React.memo(TheContent)


const TheContent = () => {
  return (
    <React.Fragment>
      <TheLayout>
      <div>
                       <AlertSystemPage/>
                   </div>
          <main className="c-main">
            <CContainer fluid>
              <Suspense fallback={loading}>
                <Switch>
              
                  {routes.map((route, idx) => {
                    return route.component && (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                          <CFade>
                            <route.component {...props} />
                          </CFade>
                        )} />
                    )
                  })}
                  <Redirect from="/" to="/dashboard" />
                </Switch>
              </Suspense>
            </CContainer>
          </main>
      </TheLayout>
    </React.Fragment>
  )
}
export default React.memo(TheContent)
